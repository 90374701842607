import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './scss/Attachment.scss';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { fetchAttachments, uploadAttachment, resetStatus, fetchAttachment } from '../../redux/Slices/attachmentSlice';
import { hideSnackBar, showSnackBar } from '../../redux/Slices/snackbarSlice';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_IMAGE_COMMAND } from '../LexicalEditor/plugins/ImagesPlugin';

import {
    $getSelection,
    $createTextNode,
    $insertNodes
} from "lexical";
import { LinkNode } from '@lexical/link';

import { config } from '../../config';

const Attachment = ({ type, editor }) => {
    const [title, setTitle] = useState('Attachment');
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [isUrlValid, setIsUrlValid] = useState(true);
    const [isTextareaFocused, setIsTextareaFocused] = useState(false); // New focus state
    const { status } = useSelector((state) => state.attachments);


    const urlRef = useRef(null);
    const fileInputRef = useRef(null);

    const { card } = useSelector((state) => state.card);
    const cardId = card.shortId;
    const dispatch = useDispatch();

    // Set title based on type
    useEffect(() => {
        setTitle(type === 'IMAGE' ? 'Image' : 'Attachment');
    }, [type]);

    useEffect(() => {
        if (urlRef.current) {
            urlRef.current.style.height = '22px';
            urlRef.current.style.height = `${urlRef.current.scrollHeight}px`;
        }
    }, [attachmentUrl]);

    useEffect(() => {
        if (status === 'uploadSucceeded') {
            dispatch(hideSnackBar());
            dispatch(fetchAttachments(cardId)).then(() => {
                dispatch(resetStatus());
            });
        } else if (status === 'uploadFailed') {
            dispatch(showSnackBar({
                message: 'File upload failed',
                type: 'error',
            }));
        }
    }, [status, dispatch, cardId]);

    // Image URL validation function
    const validateImageUrl = (url) => {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
        return imageExtensions.test(url);
    };

    const handleUrlChange = (e) => {
        const url = e.target.value;
        setAttachmentUrl(url);
        setIsUrlValid(validateImageUrl(url));
    };

    const fetchAttachment = async (cardId, attachmentId) => {
        const token = localStorage.getItem('accessToken');

        try {
            const response = await fetch(`${config.API_URI}/api/cards/attachments/${cardId}/${attachmentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch attachment');
            }

            const data = await response.json();

            setAttachmentUrl(data.attachment.url);

            return data.attachment; // Return the fetched attachment data
        } catch (error) {
            console.error('Error fetching attachment:', error);
            throw error; // Re-throw the error for further handling if needed
        }
    };



    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
            dispatch(showSnackBar({ message: 'Uploading files...', type: 'uploading' }));

            const response = await dispatch(uploadAttachment({ file, cardId }));

            console.log({ response });

            if (response.meta.requestStatus === "fulfilled") {
                const uploadedUrl = response.payload.attachment.url;

                // Await the fetchAttachment call to ensure you have the latest attachmentUrl
                const attachmentData = await fetchAttachment(cardId, response.payload.attachment._id);
                const fetchedUrl = attachmentData.url;

                // Check if the file is an image
                if (file.type.startsWith("image/")) {
                    // Insert image in the editor
                    insertImageInEditor(fetchedUrl, file.name);
                } else {
                    // Insert a link to the file in the editor if not an image
                    insertAttachmentLink(fetchedUrl, file.name);
                }

                dispatch(hideSnackBar());
            } else {
                dispatch(showSnackBar({ message: 'File upload failed', type: 'error' }));
            }
        }
    };


    const insertAttachment = (attachmentUrl) => {
        if (validateImageUrl(attachmentUrl)) {
            insertImageInEditor(attachmentUrl);
        } else {
            const fileName = attachmentUrl.split('/').pop();
            insertAttachmentLink(attachmentUrl, fileName);
        }
    };

    // Insert image node into the editor
    const insertImageInEditor = (imageUrl, fileName) => {
        const payload = {
            altText: fileName || "Uploaded Image",
            src: imageUrl,
        };
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);

        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));

    };

    // Insert link to attachment into the editor
    const insertAttachmentLink = (fileUrl, fileName) => {
        editor.update(() => {
            const selection = $getSelection();
            if (selection) {
                // Create a text node for the file name
                const textNode = $createTextNode(fileName);

                // Create a link node and set its URL
                const linkNode = new LinkNode(fileUrl);
                linkNode.append(textNode);

                $insertNodes([linkNode]);

                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
            }
        });
    };


    const attachmentAcceptType = type === 'IMAGE' ? 'image/*' : 'file';

    console.log(attachmentAcceptType);


    return (
        <div className="add-attachment">
            <h2>{title}</h2>

            <div className="group">
                <h3>{type === 'IMAGE' ? 'Image URL' : 'Attach URL'}</h3>
                <div className={`textarea-wrapper ${isTextareaFocused ? 'focused' : ''}`}>
                    <textarea
                        ref={urlRef}
                        type="url"
                        placeholder={type === 'IMAGE' ? 'Enter image URL' : 'Enter attachment URL'}
                        value={attachmentUrl}
                        onChange={handleUrlChange}
                        rows={1}
                        style={{ overflow: 'hidden' }}
                        spellCheck={false}
                        onFocus={() => setIsTextareaFocused(true)}
                        onBlur={() => setIsTextareaFocused(false)}
                    />
                </div>
                {type === 'IMAGE' && !isUrlValid && <p className="error-message">Please enter a valid image URL (e.g., .bmp, .gif, .jpg, .jpeg, .png, or .webp).</p>}
                <button onClick={() => insertAttachment(attachmentUrl)} disabled={type === 'IMAGE' && !isUrlValid}>Submit</button>
            </div>

            <div className="group">
                <h3>{type === 'IMAGE' ? 'or Upload an image from your computer' : 'or Attach a file from your computer'}</h3>
                <p>Click the 'Choose a file' button to browse and select a file from your computer.</p>
                <button onClick={() => fileInputRef.current.click()} className='attach-file-btn'>
                    {status === 'loading' ? 'Uploading...' :
                        status === 'uploadSucceeded' ? 'Upload Successful' :
                            type === 'IMAGE' ? 'Choose an image' : 'Choose a file'
                    }
                </button>

                <input
                    type="file"
                    accept={attachmentAcceptType}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
            </div>
        </div>
    );

};

export default Attachment;

import { useDispatch, useSelector } from 'react-redux';
import { togglePopover } from '../redux/Slices/popoverSlice';
import { useRef, useState } from 'react';

const useHandlePopoverClick = () => {
    const dispatch = useDispatch();
    const popoverState = useSelector((state) => state.popover);
    const [popoverTarget, setPopoverTarget] = useState(null); // Store DOM element in React state

    const handlePopoverClick = (e, contentId, props = {}) => {
        const target = e.currentTarget;
        setPopoverTarget(target); // Store target in component state

        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };

        dispatch(
            togglePopover({
                contentId,
                position,
                targetId: target.id, // Pass ID instead of the DOM element
                props,
            })
        );
    };

    return { popoverState, handlePopoverClick, popoverTarget }; // Expose target from state if needed
};

export default useHandlePopoverClick;

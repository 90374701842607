import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Provider } from 'react-redux';

import store from './redux/store';
import { getUser, refreshAccessToken } from './utils/helpers';

import Header from './components/Header';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import Workspaces from './components/Workspaces';
import UserProfile from './components/Profile/UserProfile';
import Workspace from './components/Profile/Workspace';
import Boards from './components/Profile/Boards';
import Cards from './components/Profile/CardTable';
import Collaborators from './components/Profile/Collaborators';
import Settings from './components/Profile/Settings';
import Notifications from './components/Workspaces/Notifications';

import Modal from './components/Global/ModalManager';
import Toast from './components/Global/Toast';
import PopOver from './components/Global/PopOver';

import { BoardProvider } from './context/BoardContext';

import './index.css';
import ExportImport from './components/Profile/ExportImport';
import BoardWrapper from './components/Workspaces/BoardWrapper';
import Activity from './components/Profile/Activity';

import './App.scss';
import SnackBar from './components/Global/SnackBar';
import VerificationSuccess from './components/Auth/VerificationSuccess';
import VerificationError from './components/Auth/VerificationError';
import VerificationRequired from './components/Auth/VerificationRequired';
import { fetchUser } from './redux/Slices/thunks';
import Reset from './components/Auth/Reset';
import RecoveryMail from './components/Auth/RecoveryMail';
import Verify from './components/Auth/Verify';

// Component for protected routes with verification check
const ProtectedRoute = ({ element: Component, loggedIn, verified, redirectTo = "/login", ...rest }) => {
  if (!loggedIn) return <Navigate to={redirectTo} />;
  if (!verified) return <Navigate to="/verification-required" />;
  return <Component {...rest} />;
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userProfileBoardsLink, setUserProfileBoardsLink] = useState('');
  const isCheckingLoginStatus = useRef(false);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const checkLoginStatus = async () => {
      if (isCheckingLoginStatus.current) return;

      isCheckingLoginStatus.current = true;
      const data = await getUser();

      if (!data?.user?._id) {
        const refreshed = await refreshAccessToken();
        setLoggedIn(refreshed);
        setVerified(false);
      } else {
        setLoggedIn(true);
        setVerified(data.user.verified);
        setUserProfileBoardsLink(`/u/${data.user.username}/boards`);
      }

      setLoading(false);
    };

    checkLoginStatus();
  }, [dispatch, user]);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch]);

  if (loading) {
    return null; // You might want to display a loading spinner here
  }

  return (
    <Provider store={store}>
      <Router>
        {loggedIn && <Header />}
        <BoardProvider>
          <Routes>
            <Route path="/workspace" element={<ProtectedRoute element={Workspace} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/" element={<ProtectedRoute element={() => <Navigate to={userProfileBoardsLink} />} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/u/:userName/profile" element={<ProtectedRoute element={UserProfile} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/u/:userName/boards" element={<ProtectedRoute element={Workspace} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/u/:userName/cards" element={<ProtectedRoute element={Cards} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/u/:userName/activity" element={<ProtectedRoute element={Activity} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/u/:userName/settings" element={<ProtectedRoute element={Settings} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/w/:workspaceSlug" element={<ProtectedRoute element={Workspaces} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/b/:boardId/:boardSlug" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/b/:boardId/" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/c/:cardId/:cardSlug" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/c/:cardId/" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} verified={verified} />} />

            <Route path="/w/:shortId/boards" element={<ProtectedRoute element={Boards} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/w/:shortId/members" element={<ProtectedRoute element={Collaborators} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/w/:shortId/settings" element={<ProtectedRoute element={Settings} loggedIn={loggedIn} verified={verified} />} />
            <Route path="/notifications" element={<ProtectedRoute element={Notifications} loggedIn={loggedIn} verified={verified} />} />

            <Route path="/login" element={!loggedIn ? <Login /> : <Navigate to={userProfileBoardsLink} />} />
            <Route path="/signup" element={!loggedIn ? <Signup /> : <Navigate to={userProfileBoardsLink} />} />
            <Route path="/verify" element={<Verify />} /> {/* Only one verify route */}
            <Route path="/recovery" element={!loggedIn ? <RecoveryMail /> : <Navigate to={userProfileBoardsLink} />} />
            <Route path="/reset" element={!loggedIn ? <Reset /> : <Navigate to={userProfileBoardsLink} />} />

            {
              !verified && (
                <>
                  <Route path="/verification-error" element={<VerificationError />} />
                  <Route path="/verification-required" element={<VerificationRequired />} />
                </>
              )
            }
            {
              verified && (
                <Route path="/verification-success" element={<VerificationSuccess />} />
              )
            }

            <Route path="/import-export" element={<ProtectedRoute element={ExportImport} loggedIn={loggedIn} verified={verified} />} />
          </Routes>
        </BoardProvider>

        <Modal />
        <Toast />
        <SnackBar />
        <PopOver />
      </Router>
    </Provider>
  );
}

export default App;

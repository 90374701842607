import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { marked } from 'marked';

import { setBoardId, selectBoardId } from '../../redux/Slices/boardIdSlice';


import { useBoard } from '../../context/BoardContext';

import { find, sanitizeHtml, toSlug, uploadFile } from '../../utils/helpers';
import { format } from 'date-fns';

import { config } from '../../config';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import CardDetailsSidebar from '../Workspaces/CardDetailsSidebar';
import SkeletonCardDetails from '../Skeletons/SkeletonCardDetails';

import NotFound from '../400';

import {
    fetchAttachments,
    uploadAttachment,
    editAttachment,
    deleteAttachment,
    resetStatus,
} from '../../redux/Slices/attachmentSlice';

import {
    mentionedMemberIds,
    resetMentionedIds,
} from "../../redux/Slices/mentionedSlice";

import './scss/editor.style.scss';

import CardMemberSlice, { fetchCardMembers, addCardMember, removeCardMember } from '../../redux/Slices/cardMemberSlice';
import Comments from './Comments';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import PopOver from '../Global/PopOver';
import Checklist from './Checklists';
import IconCardTitle from '../icons/IconCardTitle';
import IconDescription from '../icons/IconDescription';
import Attachments from './Attachments';
import CardMembers from './CardMembers';
import CardLabels from './CardLabels';
import Editor2 from '../Editor/Editor';
import Editor from '../LexicalEditor/Editor';

import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';

import { cardData } from '../../redux/Slices/cardSlice';
import { fetchUser } from '../../redux/Slices/thunks';
import Checkbox from '../Global/Checkbox';
import { fetchCardById } from '../../redux/Slices/cardSlice';
import { fetchBoardById } from '../../redux/Slices/boardSlice';
import Activities from './Activities';
import { showToast } from '../../redux/Slices/toastSlice';
import { fetchMembers } from '../../redux/Slices/memberSlice';
import { Helmet } from 'react-helmet';


const CardDetails = ({ _setBoardSlug }) => {


    const { cardId } = useParams();

    const navigate = useNavigate(); // Use navigate hook
    const { boardPermalink } = useBoard();
    const [isValid, setIsValid] = useState(true);

    // const [card, setCard] = useState({});
    const { card, status } = useSelector((state) => state.card);

    const [title, setTitle] = useState(card?.title || '');
    const [description, setDescription] = useState(card?.description || '');

    const [isModified, setIsModified] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [isTextareaFocused, setIsTextareaFocused] = useState(false);


    const { handlePopoverClick } = useHandlePopoverClick();

    const textAreaRef = useRef(null);
    const quillRef = useRef(null);
    const fileInputRef = useRef(null);

    const [actionListId, setActionListId] = useState(null);
    const [actionListTitle, setActionListTitle] = useState(null);

    const mentionedIds = useSelector((state) => state.mentioned.mentionedMemberIds);
    const { cardMembers, boardMembers, loading } = useSelector(state => state.member);
    const members = cardMembers;

    const boardId = useSelector(selectBoardId);

    const dispatch = useDispatch();

    // const attachments = card?.attachments;

    const { attachmentStatus, attachments } = useSelector((state) => state.attachments);
    const { user } = useSelector((state) => state.user);


    useEffect(() => {
        if (cardId) {
            dispatch(fetchCardById(cardId));

        }
    }, [cardId, dispatch]);

    useEffect(() => {
        if (card && !isEditingDescription) {
            setTitle(card.title);
            setDescription(card.description);
            setActionListId(card.actionList);
            setActionListTitle(card.actionListTitle);
            dispatch(cardData(card));
            dispatch(setBoardId(card.board));
        }
    }, [card, isEditingDescription, dispatch]);


    useEffect(() => {
        if (!user) {
            dispatch(fetchUser());
        }
    }, []);

    useEffect(() => {
        dispatch(fetchAttachments(cardId)).then(() => {
            dispatch(resetStatus());
        });

    }, [attachmentStatus, dispatch, cardId]);


    useEffect(() => {
        console.log('card details updated', boardMembers);
        if (boardId && !boardMembers.length > 0) {
            dispatch(fetchMembers({ type: 'board', id: boardId }));
        }

    }, [boardId, dispatch]);

    const handleFileUpload = async (e) => {
        e.preventDefault();

        if (!quillRef.current) {
            return;
        }

        try {
            const attachments = await uploadFile(e, cardId);

            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            quill.insertEmbed(range.index, 'link', attachments.attachment.url);

            // Update local state for the description with the new attachment
            setDescription((prevDescription) => {
                return `${prevDescription}<a href="${attachments.attachment.url}" target="_blank">${attachments.attachment.name}</a>`;
            });

            // Dispatch Redux action to fetch attachments if necessary
            dispatch(fetchAttachments({ cardId }));

        } catch (error) {
            console.error('Error uploading file:', error.message);
        }
    };


    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '24px';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }

    }, [title]);


    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const length = quill.getLength(); // Get the length of the content
            quill.setSelection(length - 1, 0); // Set the cursor at the end
            quill.focus(); // Focus the editor
        }
    }, [isEditingDescription]);



    const handleUpdateCard = async () => {
        const token = localStorage.getItem('accessToken');
        const sanitizedHtmlDes = description;

        // Save previous state for rollback if API call fails
        const previousTitle = title;
        const previousDescription = card.description;

        // Optimistically update the UI state
        setIsModified(false);
        dispatch(cardData({
            ...card,
            title,
            description: sanitizedHtmlDes,
        }));

        try {
            const response = await fetch(config.API_URI + `/api/cards/update/${cardId}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    title,
                    description: sanitizedHtmlDes,
                    mentionedIds, // Pass the mentioned IDs
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update card');
            }

            const card = await response.json();
            dispatch(cardData(card)); // Update with the response from the server
            dispatch(fetchBoardById(card.board));

        } catch (error) {
            console.error('Error updating card:', error);

            // Revert to previous state if update fails
            setTitle(previousTitle);
            setDescription(previousDescription);
            dispatch(cardData({
                ...card,
                title: previousTitle,
                description: previousDescription,
            }));
        }
    };



    const handleCloseCardPopup = (event) => {
        if (!card) {
            dispatch(fetchCardById(cardId));
        }
        if (event.target.classList.contains('zoobbe-card-modal-container') || event.target.closest('.close-card')) {
            navigate(card.boardLink);
        }
    };

    const [isChecked, setIsChecked] = useState(false);

    // Synchronize isChecked with the card's current status
    useEffect(() => {
        if (card?.dueDate?.status === 'Completed') {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }, [card]);

    useEffect(() => {
        if (!card) return;

        const currentStatus = card?.dueDate?.status;

        if (card?.dueDate) {
            let newStatus = 'Pending';

            if (isChecked) {
                newStatus = 'Completed';
            } else if (new Date(card.dueDate.date) < new Date()) {
                newStatus = 'Overdue';
            } else if (new Date(card.dueDate.date) >= new Date()) {
                newStatus = 'In-Progress';
            }

            if (currentStatus !== newStatus) {
                updateDueDateStatus(newStatus, card.dueDate);
            }
        }
    }, [isChecked]);

    const updateDueDateStatus = async (status, dueDate) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/duedate`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    ...dueDate,
                    status,
                }),
            });

            if (response.ok) {
                const updatedCard = await response.json();
                dispatch(cardData(updatedCard)); // Update the card in Redux store

            } else {
                console.error('Failed to update due date status');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const [isWatching, setIsWatching] = useState(false);

    useEffect(() => {
        if (card && user) {
            const idExists = card.watchers?.includes(user?.user?._id);
            setIsWatching(idExists);
        }

    }, [card, user]);

    const handleWatch = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const endpoint = isWatching ? 'unwatch' : 'watch';
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    cardId,
                }),
            });

            if (!response.ok) {
                dispatch(showToast({ message: `Failed to ${endpoint} the card`, type: 'error' }));
                throw new Error(`Failed to ${endpoint} the card`);
            }

            // Toggle watch state on success
            setIsWatching(!isWatching);

        } catch (error) {
            console.error(error);
        }
    };

    const memberIds = members?.map(member => member?._id);
    const isUserMember = user && user.user ? memberIds.includes(user?.user?._id) : false;

    if (status === 'loading' || loading) {
        return (
            <SkeletonCardDetails />
        )
    }

    if (!isValid) {
        return <NotFound />;
    }

    if (!cardId || !card) {
        return null;
    }

    return (
        <>
            <Helmet>
                {title} on {boardId} | Zoobbe
            </Helmet>
            
            <div className="zoobbe-card-wrapper">
                <div className='zoobbe-card-modal-container' onClick={handleCloseCardPopup}>
                    <div className="zoobbe-card-details">
                        <div className="zoobbe-card-content">
                            <div className="zoobbe-card-details-body">
                                <div className="zoobbe-card-details-header">
                                    <div className='card-heading'>
                                        <span className="material-symbols-outlined">
                                            video_label
                                        </span>
                                        <div className={`textarea-wrapper ${isTextareaFocused ? 'focused' : ''}`}>
                                            <textarea
                                                ref={textAreaRef}
                                                className="zoobbe-card-title-textarea"
                                                value={title}
                                                onChange={(e) => {
                                                    setTitle(e.target.value);
                                                    setIsModified(true);
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onBlur={() => {
                                                    handleUpdateCard();
                                                    setIsTextareaFocused(false);
                                                }}
                                                onFocus={() => setIsTextareaFocused(true)}
                                                spellCheck={isTextareaFocused ? 'true' : 'false'}
                                            />
                                        </div>
                                        <div className="close-card-container">
                                            <div className='close-card'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414z" fill="currentColor" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="zoobbe-card-details-status">
                                        in list
                                        <span className='actionlist' id='popover-movecard' onClick={(e) => handlePopoverClick(e, 'moveCard')}>{actionListTitle}</span>
                                    </div>
                                </div>
                                <div className="zoobbe-card-details-info">
                                    <div className='zoobbe-card-details-info-left'>
                                        <div className='card-details-top'>
                                            <CardMembers cardId={cardId} />

                                            {
                                                isUserMember && (
                                                    <div className="zoobbe-notifications">
                                                        <h5>Notifications</h5>
                                                        <div className='notifications-content' onClick={handleWatch}>
                                                            <span className="material-icons-outlined">visibility</span>
                                                            <span className="zoobbe-watching">
                                                                {isWatching ? 'Watching' : 'Watch'}
                                                            </span>
                                                            {isWatching && (
                                                                <div className="watching-checkmark">
                                                                    <span className="material-symbols-outlined">check</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            }


                                            {
                                                card?.dueDate?.date && (
                                                    <div className="zoobbe-duedate">
                                                        <h5>Due Date</h5>
                                                        <div className='duedate-content'>
                                                            <Checkbox
                                                                checked={isChecked}
                                                                onChange={(checked) => setIsChecked(checked)}
                                                            />

                                                            <div className="duedate" id='popover-duedate' onClick={(e) => handlePopoverClick(e, 'addDueDate')}>
                                                                <span className="zoobbe-watching">
                                                                    {format(card.dueDate.date, 'MMM dd')}, {card.dueDate.dueTime}
                                                                </span>
                                                                {
                                                                    card.dueDate.status === 'Completed' && (
                                                                        <span className="completed-text">Completed</span>
                                                                    )
                                                                }
                                                                {
                                                                    card.dueDate.status === 'Overdue' && (
                                                                        <span className="overdue-text">Overdue</span>
                                                                    )
                                                                }

                                                                <span className="material-icons-outlined">keyboard_arrow_down</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <CardLabels cardId={cardId} />


                                        </div>

                                        <div className={`zoobbe-description ${isEditingDescription ? 'currently-editing' : ''}`}>

                                            <div className="description-header">
                                                <h3>
                                                    <span className="material-symbols-outlined">
                                                        subject
                                                    </span>
                                                    Description
                                                </h3>

                                                <button className="edit-description-button" onClick={() => setIsEditingDescription(true)}>
                                                    <span className="material-symbols-outlined">
                                                        edit_note
                                                    </span>
                                                    Edit
                                                </button>

                                            </div>

                                            {!description && !isEditingDescription && (
                                                <div className="zoobbe-card-no-description" onClick={() => setIsEditingDescription(true)}>Add a more detailed description of the task here... </div>
                                            )}

                                            {!isEditingDescription && description && (
                                                <div
                                                    className="card-description-content quill"
                                                    dangerouslySetInnerHTML={{ __html: marked(card.description) }}
                                                    onClick={(e) => {
                                                        const anchor = e.target.closest('a');
                                                        if (anchor) {
                                                            window.open(anchor.href, '_blank');
                                                            e.preventDefault();
                                                        } else {
                                                            setIsEditingDescription(true);
                                                        }
                                                    }}
                                                />
                                            )}



                                            {isEditingDescription && card && (
                                                <>

                                                    <Editor
                                                        value={description}
                                                        setDescription={setDescription}
                                                        setIsEditingDescription={setIsEditingDescription}
                                                        handleUpdateCard={handleUpdateCard}
                                                        boardId={boardId}
                                                    />


                                                </>
                                            )}
                                        </div>

                                        {
                                            attachments?.length > 0 && (
                                                <Attachments cardId={cardId} />
                                            )
                                        }

                                        <Checklist cardId={cardId} />

                                        <div className="zoobbe-card-details-footer">
                                            <Comments cardId={cardId} isActivityDetails={card.isActivityDetails} />
                                            <Activities activities={card.activities} cardId={cardId} />
                                        </div>
                                    </div>
                                    <div className='zoobbe-card-details-info-right'>
                                        <CardDetailsSidebar
                                            cardId={cardId}
                                            boardId={boardId}
                                            isArchived={card?.archived}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="space-bottom"></div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default CardDetails;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isVisible: false,
    contentId: null,
    position: { top: 0, left: 0 },
    targetId: null, // Store only the target's ID
    props: {},
};

const popoverSlice = createSlice({
    name: 'popover',
    initialState,
    reducers: {
        togglePopover: (state, action) => {
            const { contentId, position, targetId, props } = action.payload;

            if (state.isVisible && state.contentId === contentId) {
                // If the same popover is already visible, hide it
                state.isVisible = false;
                state.contentId = null;
                state.position = { top: 0, left: 0 };
                state.targetId = null;
                state.props = {};
            } else {
                // Show new popover or different popover
                state.isVisible = true;
                state.contentId = contentId;
                state.position = position;
                state.targetId = targetId;
                state.props = props || {};
            }
        },
        updatePopoverPosition: (state, action) => {
            state.position = action.payload;
        },
    },
});

export const { togglePopover, updatePopoverPosition } = popoverSlice.actions;
export default popoverSlice.reducer;

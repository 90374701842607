// src/components/Attachments/Attachments.js

import React, { useEffect, useRef } from 'react';
import './scss/Attachments.scss';
import { useDispatch, useSelector } from 'react-redux';
import { timeAgo, getFileExtension } from '../../utils/helpers';
import {
    fetchAttachments,
    uploadAttachment,
    editAttachment,
    deleteAttachment,
    resetStatus,
} from '../../redux/Slices/attachmentSlice';

import { togglePopover } from '../../redux/Slices/popoverSlice';

import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
import { hideSnackBar, showSnackBar } from '../../redux/Slices/snackbarSlice';


const Attachments = ({ cardId }) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const { attachments, status } = useSelector((state) => state.attachments);

    const error = useSelector((state) => state.attachments.error);

    const { handlePopoverClick } = useHandlePopoverClick();

    useEffect(() => {

        if (status === 'uploadSucceeded') {

            dispatch(fetchAttachments(cardId)).finally(() => {
                dispatch(resetStatus());
                dispatch(hideSnackBar());

            });
        }
    }, [status, dispatch, cardId]);


    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {

            dispatch(showSnackBar({
                message: 'Uploading files...',
                type: 'uploading'
            }));


            dispatch(uploadAttachment({ file, cardId }));
        }
    };

    const handleAttachmentEdit = (attachmentId, newName) => {
        dispatch(editAttachment({ cardId, attachmentId, newName }));
    };

    const handleAttachmentDelete = (e, attachmentId) => {
        handlePopoverClick(e, 'deleteAction', { type: 'ATTACHMENT', id: attachmentId, cardId });
    };

    return (
        <div className="attachments">

            <div className="attachments-header">
                <h3 className="attachments-title">
                    <span className="material-symbols-outlined">
                        attach_file
                    </span>
                    Attachments
                </h3>

                <button className="upload-button" onClick={() => fileInputRef.current.click()}>
                    <span className="material-symbols-outlined">
                        attach_file_add
                    </span>
                    Attach File</button>
            </div>

            <ul className="attachments-list">
                {attachments?.map((attachment) => {

                    // if (!attachment) return;

                    return (
                        <li key={attachment._id} className="attachment-item">
                            <div className="attachment-icon">
                                <span className="icon">{getFileExtension(attachment.url)}</span>
                            </div>

                            <div className="attachment-info">
                                <div className='attachment-wrapper'>
                                    <div className="attachment-name">
                                        {attachment.name}
                                    </div>
                                    <div className="attachment-actiion-buttons">
                                        <a href={attachment.url} target="_blank" rel="noopener noreferrer" className="attachment-download action-icon">
                                            <span className="material-symbols-outlined">
                                                download
                                            </span>
                                        </a>
                                        <div className="attachment-comment action-icon">
                                            <span className="material-symbols-outlined">
                                                comment
                                            </span>
                                        </div>
                                        <div className="attachment-delete action-icon" id={`popover-attachment-delete-${attachment._id}`} onClick={(e) => handleAttachmentDelete(e, attachment._id)}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </div>
                                        <div className="attachment-edit action-icon" onClick={() => {
                                            const newName = prompt("Enter new name:", attachment.name);
                                            if (newName) handleAttachmentEdit(attachment._id, newName);
                                        }}>
                                            <span className="material-symbols-outlined">
                                                edit_square
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="attachment-details">
                                    <span>Added {timeAgo(attachment.uploadedAt)}</span>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
            />
        </div>
    );
};

export default Attachments;
